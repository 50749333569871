/* eslint-disable @typescript-eslint/no-unsafe-argument */
import * as React from "react"
import { DatePicker, Form } from "antd"
import FieldWithHelp from "./support/FieldWithHelp"
import { PureDate, PureDateTime } from "../../../shared/legacy/puredatetime"
import getLocale from "../../../apps/shared/getLocale"

import moment from "moment"
import type { Dayjs } from "dayjs"
import dayjs from "dayjs"

/**
 * The input date can be one of several alternatives:
 * - a Date (deprecated)
 * - a Date.toJSON result (deprecated)
 * - a DateTime or DateTimeDate instance
 * - a DateTime or DateTimeDate .toJSON result
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertDateForField = (d: any): Dayjs => {
  if (d === undefined || d === null || d === "") {
    return undefined
  } else if (PureDate.isValidJSON(d)) {
    return dayjs(PureDate.fromJSON(d).toJSDate())
  } else if (PureDateTime.isValidJSON(d)) {
    return dayjs(PureDateTime.fromJSON(d).toJSDate())
  } else if (d.toJSDate !== undefined) {
    // new DateTime object
    return dayjs(d.toJSDate())
  } else if (d && moment(d).isValid()) {
    // old JS Date
    return dayjs(d)
  } else {
    console.log("WARNING! Input date conversion falls through to default!")
    return undefined
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const convertDateFromField = (value: any) => {
  if (value !== undefined && moment(value).isValid()) {
    return PureDate.fromJSDate(value.toDate())
  } else {
    return ""
  }
}

const DateUI = ({
  input: { value, onChange },
  label,
  meta,
  dataE2E,
  ...props
}) => {
  return (
    <FieldWithHelp {...props}>
      <label>{label}</label>
      <Form.Item
        label={""}
        validateStatus={meta.error ? "error" : "success"}
        hasFeedback={!!meta.error}
        help={meta.error}
      >
        <DatePicker
          {...props}
          value={convertDateForField(value)}
          allowClear={true}
          onChange={data => onChange(convertDateFromField(data))}
          data-e2e-test-id={dataE2E}
          format={getLocale(getLang()).dateFormat}
        />
      </Form.Item>
    </FieldWithHelp>
  )
}

function ReadOnlyField({ input: { value }, label, dataE2E }) {
  return (
    <div className="field detailsConnectedFormField">
      <label>{label}</label>
      <div
        className="detailsConnectedFormFieldValue"
        data-e2e-test-id={dataE2E}
      >
        {localDate(value)}
      </div>
    </div>
  )
}

// TODO: Duplicated in remantic-table, needs common util!
function localDate(value) {
  if (value !== undefined) {
    // Convert anything that isn't a JS Date
    if (PureDate.isValidJSON(value)) {
      value = PureDate.fromJSON(value).toJSDate()
    } else if (PureDateTime.isValidJSON(value)) {
      value = PureDateTime.fromJSON(value).toJSDate()
    }
  }
  if (value && value !== "") {
    value = new Date(value).toLocaleDateString()
  }
  return `${value}`
}

function getLang() {
  let lang = "en" // simplifying a bit here
  if (navigator.languages !== undefined && navigator.languages.length > 0) {
    lang = navigator.languages[0]
  } else {
    lang = navigator.language || lang
  }
  const l = lang.substr(0, 2)
  return ["en", "de"].includes(l) ? l : "en"
}

export default (readOnly: boolean): any => (!readOnly ? DateUI : ReadOnlyField)
